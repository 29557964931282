import React, { useEffect, useMemo, useState } from 'react';
import chunk from 'lodash.chunk';
import clamp from 'lodash.clamp';
import { Entity } from '@backstage/catalog-model';
import { GridView } from './GridView';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { ListView } from './ListView/ListView';

const usePackageDiscoveryContentStyles = makeStyles(theme => ({
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
}));

interface PackageDiscoveryContentProps {
  packages: Entity[];
  titleFilter: string;
  viewMode: 'grid' | 'list';
}

const GRID_VIEW_CHUNK_SIZE = 6;
const LIST_VIEW_CHUNK_SIZE = 12;

export const PackageDiscoveryContent = ({
  packages,
  titleFilter,
  viewMode,
}: PackageDiscoveryContentProps) => {
  const classes = usePackageDiscoveryContentStyles();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const chunkedFilteredPackages = useMemo(() => {
    const filteredPackages = packages.filter(packageEntity =>
      packageEntity.metadata.title
        ?.toLowerCase()
        .includes(titleFilter.toLowerCase()),
    );

    return chunk(
      filteredPackages,
      viewMode === 'grid' ? GRID_VIEW_CHUNK_SIZE : LIST_VIEW_CHUNK_SIZE,
    );
  }, [packages, titleFilter, viewMode]);

  const clampedPageIndex = clamp(
    currentPage - 1,
    0,
    chunkedFilteredPackages.length - 1,
  );

  const currentChunk = chunkedFilteredPackages[clampedPageIndex];

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // Reset pagination on viewMode change because page sizes are different
    setCurrentPage(1);
  }, [viewMode]);

  return (
    !!chunkedFilteredPackages.length && (
      <>
        {viewMode === 'grid' ? (
          <GridView chunk={currentChunk} />
        ) : (
          <ListView chunk={currentChunk} />
        )}
        <Pagination
          count={chunkedFilteredPackages.length}
          className={classes.pagination}
          onChange={onPageChange}
          page={currentPage}
          color="primary"
        />
      </>
    )
  );
};
