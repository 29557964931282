import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const adeoThemeDark = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      background: {
        default: '#051723',
        paper: '#082435',
      },
      primary: {
        main: '#48bac4',
      },
      secondary: {
        main: '#0b96cc',
      },
      error: {
        main: '#ea302d',
      },
      warning: {
        main: '#ea7315',
      },
      info: {
        main: '#0b96cc',
      },
      success: {
        main: '#46a610',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      grey: {
        50: '#F9FCFD',
        100: '#EAEDEF',
        200: '#CDD4D8',
        300: '#B0BBC0',
        400: '#92A2A9',
        500: '#758992',
        600: '#5B737D',
        700: '#405D68',
        800: '#264653',
        900: '#082435',
      },
      errorBackground: '#fdeaea',
      warningBackground: '#ff0000',
      infoBackground: '#daeff7',
      navigation: {
        background: '#082435',
        indicator: '#ffffff',
        color: '#cfd2d8',
        selectedColor: '#ffffff',
        navItem: {
          hoverBackground: '#007f8c',
        },
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#007f8c', '#074147'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#007f8c', '#074147'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#007f8c', '#074147'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#007f8c', '#074147'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#007f8c', '#074147'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#007f8c', '#074147'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#007f8c', '#074147'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#007f8c', '#074147'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#007f8c', '#074147'], shape: shapes.wave }),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        overline: {
          lineHeight: 'initial',
        },
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: ({ theme }) => ({
          fontFamily: theme.typography.body1.fontFamily,
          fontWeight: theme.typography.body1.fontWeight,
          fontSize: theme.typography.body1.fontSize,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),

          textDecoration: 'none',
          letterSpacing: 'normal',

          minWidth: 'auto',
          textTransform: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        }),

        tabsWrapper: ({ theme }) => ({
          borderBottom: `2px solid ${theme.palette.divider}`,
          position: 'sticky',
          top: '64px',
          zIndex: 100,
        }),
      },
    },
  },
});
