import React, { useState } from 'react';
import {
  Container,
  debounce,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Header } from '../header/Header';
import {
  GridViewIcon,
  HomeIcon,
  Inventory2SearchIcon,
  ListViewIcon,
} from 'backstage-plugin-icons-react';
import {
  Breadcrumbs,
  BreadcrumbsItems,
} from '@internal/backstage-plugin-adeo-core-components-react';
import { usePackages } from './usePackages';
import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@material-ui/core/styles';
import { PackageDiscoveryContent } from './PackageDiscoveryContent';
import { useViewMode, ViewMode } from './useViewMode';

const usePackageDiscoveryStyles = makeStyles<Theme, { viewMode: ViewMode }>(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      gap: theme.spacing(2),
      height: '100%',
      marginTop: theme.spacing(5),
    },
    entityPresentation: {
      position: 'relative',
    },
    actionButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
    },
    searchContainer: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    searchFormControl: {
      flex: 1,
    },
    searchInput: {
      background: theme.palette.background.paper,
    },
    searchIcon: {
      color: theme.palette.text.hint,
    },
    switchViewContainer: {
      background: theme.palette.background.paper,
      display: 'flex',
    },
    switchViewButton: {
      borderRadius: 0,
      borderColor: `${theme.palette.divider} !important`,
      borderStyle: 'solid !important',
      '&:first-child': {
        backgroundColor: props =>
          props.viewMode === 'grid' ? theme.palette.action.selected : `initial`,
        borderWidth: '1px',
        borderTopLeftRadius: theme.spacing(0.5),
        borderBottomLeftRadius: theme.spacing(0.5),
      },
      '&:last-child': {
        backgroundColor: props =>
          props.viewMode === 'list' ? theme.palette.action.selected : `initial`,
        borderWidth: '1px 1px 1px 0',
        borderTopRightRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
      },
    },
  }),
);

export const PackageDiscovery = () => {
  const [viewMode, setViewMode] = useViewMode();

  const classes = usePackageDiscoveryStyles({ viewMode });

  const [titleFilter, setTitleFilter] = useState<string>('');

  const onTitleFilterChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitleFilter(e.target.value);
    },
    300,
  );

  const { data: packages } = usePackages();

  return (
    <>
      <Header withBottomBorder>
        <Breadcrumbs>
          <BreadcrumbsItems label="Home" to="/" Icon={HomeIcon} isCompact />
          <BreadcrumbsItems
            label="Package discovery"
            Icon={Inventory2SearchIcon}
            isLast
          />
        </Breadcrumbs>
      </Header>

      <Container maxWidth="lg" className={classes.root}>
        <div className={classes.searchContainer}>
          <div className={classes.switchViewContainer}>
            <IconButton
              className={classes.switchViewButton}
              onClick={() => setViewMode('grid')}
              data-testid="grid-view-button"
            >
              <GridViewIcon />
            </IconButton>
            <IconButton
              className={classes.switchViewButton}
              onClick={() => setViewMode('list')}
              data-testid="list-view-button"
            >
              <ListViewIcon />
            </IconButton>
          </div>
          <TextField
            variant="outlined"
            placeholder="Search a package"
            className={classes.searchFormControl}
            InputProps={{
              className: classes.searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              ),
            }}
            onChange={onTitleFilterChange}
          />
        </div>

        <PackageDiscoveryContent
          packages={packages}
          titleFilter={titleFilter}
          viewMode={viewMode}
        />
      </Container>
    </>
  );
};
